.footer{
    padding: 4rem 6rem;
    background: #000;
    color: #fff;
}

.footer__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
}
.footer__top svg {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.footer__top svg:hover{
    color: #01959a;
}
.footer__bottom{
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer__bottom div{
    display: flex;
    flex-direction: column;

}
.footer__bottom h4{
    font-size: 1.3rem;
    padding: 1rem 0 .8rem 0;
}

.footer__bottom  a{
    color: #fafafa;
    padding-bottom:.4rem ;
    font-size: 1.1rem;
}
.footer__bottom a:hover{
    color: #01959a;
    transition: .2s ease-in-out;
}



@media screen and (max-width: 850px) {
    .footer{
        padding: 4rem 2rem;
    }
    .footer svg{
        margin: 1rem 1rem 0 0;
    }
    .footer__bottom div{
        width: 50%;
    }
    
}