.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero__mid{
    width: 100%;
    height: 70vh;
    position: relative;
}

.backgroundStyle{
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.hero__mid h1 {
    padding-top: 4rem;
    color: #222;
}



.imgstyle{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.hero__text{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50% );
    width: 100%;
}
.hero__text h1{
    font-size: 3rem;
    font-weight: 800;
    background-color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}
.hero__text p{
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 0 2rem 0;
}
.hero__text .show{
    background: #fff;
    border-radius: 6px;
    padding: 1rem 2rem ;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
    
}


.hero__text .show:hover{
    background-color: #01959a;
    transition: .2s ease-in-out;
}


.hero__text .hide{
    display: none;
}

@media screen and(max-width:55px) {
    .hero__text h1 {
        padding: 10px 20px;
    }
    .hero__text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;

    }
    .hero__text .show{
        border-radius: 6px;
        padding:.61rem 1.1rem ;
        font-size: 1rem;

        
    }
    
}