
.navbar__item{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;  
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);  
    width: 95%;
    height: 88px;
    border-radius: 13px;
    overflow: hidden;
    position: fixed;
    top: 20px ;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
}

.navbar__item h1{
    color: #222;
    font-size: 2rem;
    cursor: pointer;
}

.nav__menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 10px;
    align-items: center;
}

.nav__links{
    display: flex;
    align-items: center;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding:  0 1.3rem;
    white-space: nowrap;
}

.nav__links span{
    padding: 15px 5px ;



}
.nav__links:hover {
    background: #01959a;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}
.sign{
    display: none;
}

button{
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition:  0.2s ease-in-out;
}

button:hover{
    background: #151516;
    color: #fff;
}