.about__container{
    margin: 4rem 6rem;
    text-align: start;
}
.about__container h1{
    padding: 1rem;
}
.about__container p{
    padding: 2rem;
}

@media screen and (max-width: 850px) {
    .about__container{
        margin: 4rem 2rem;
        
    }
}